<template>
  <div class="development">
    <heads></heads>
    <div class="con_box" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <ul class="content">
        <li v-for="(item, index) in videoReviews" :key="index">
          <router-link :to="{
            path: '/development/detail',
            query: { id: item.id },
          }">
            {{ item.name }}
          </router-link>
        </li>
        <li>
          <router-link to="/article/developmentHistory">
            发展历程（文字版）
          </router-link>
        </li>
      </ul>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getvideoReviews } from "@api/public";

export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      videoReviews: [],
      backgroud: ""
    };
  },
  mounted: function () {
    let that = this;
    getvideoReviews().then(function (res) {
      that.$set(that, "videoReviews", res.data.list);
    });
  },
  created: function () {
    document.title = this.$route.meta.title;
    this.backgroud = this.$route.meta.background;
  },
};
</script>
<style>
.development>.con_box {
  width: 100%;
  height: 974px;
  background-size: cover;
  background-position: center 0;
  -webkit-background-size: cover;
  -o-background-size: cover;
}

.development .content {
  width: 960px;
  height: 400px;
  position: relative;
  top: 235px;
  left: 535px;
  display: flex;
  box-sizing: border-box;
  padding-top: 155px;
  padding-left: 160px;
}

.development .content>li {
  font-family: kaiti;
  font-weight: 800;
  writing-mode: vertical-rl;
  padding-right: 40px;
  font-size: 20px;
}

.development .content>li>a {
  color: #000;
  writing-mode: vertical-rl;
}
</style>